import * as React from "react";
import { IconProps } from "..";

const Plus = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "Plus", titleId = "plus", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M4.531 14.5H24.47M14.5 4.531V24.47"
          stroke="currentColor"
          strokeWidth={3}
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
export default Plus;
